<template>
  <div
    class="sidebar-menu"
    :class="[
      {'level-1-visible': levelVisible === 1},
      {'level-2-visible': levelVisible === 2},
    ]"
  >
    <!--
      **
      * Header
      **
    -->
    <div class="sidebar-header">
      <div class="back">
        <span
          v-if="levelVisible === 2"
          @click="
            levelVisible = 1,
            menuTitle = $t('Menu_Title')
          "
        >
          <ChevronLeftIcon />
        </span>
      </div>
      <div class="title">
        <span>
          {{ menuTitle }}
        </span>
      </div>
      <div class="close" @click="hideMobileSidebar">
        <CloseIcon />
      </div>
    </div>
    <div class="sidebar-body level-1">
      <!--
        **
        * Level 1 Menu
        **
      -->
      <div class="main-menu">
        <div
          v-for="(item, index) in visibleMainMenuItems"
          :key="'navigation-' + index"
          class="item"
          :style="'--link-color:' + item.linkColor"
        >
          <div
            v-if="item.toggleMobileSubmenu"
            class="link"
            @click="
              levelVisible = 2,
              menuTitle = item.label
            "
          >
            <span>
              {{ item.label }}
            </span>
            <ChevronRightIcon />
          </div>
          <div v-else @click="trackMenuClick(item)">
            <mp-link
              :to="$u(item.link)"
              class="link"
            >
              <span>
                {{ item.label }}
                <Tag
                  v-if="item.tagLabel"
                  :text="item.tagLabel"
                  :text-color="item.tagForegroundColor"
                  :text-color-hex="item.tagForegroundColorHex"
                  :background-color="item.tagBackgroundColor"
                  :background-color-hex="item.tagBackgroundColorHex"
                />
              </span>
            </mp-link>
          </div>
        </div>
      </div>

      <!--
        **
        * Collections
        **
      -->
      <div class="collections">
        <div class="collection-section">
          <div class="collection-headline">
            {{ mainMenu.content.collectionsLabel }}
          </div>
          <div class="collection">
            <CollectionImage
              v-for="(collection, indexKey) in visibleCollections"
              :key="indexKey"
              :image="collection.image"
              :label="collection.label"
              :link="collection.link"
              view="mobile"
            />
          </div>
        </div>
      </div>

      <!--
        **
        * Country Selector
        **
      -->
      <div class="country-wrapper">
        <div class="label">
          <span>
            {{ $t('Global_Shopping_From') }}
          </span>
        </div>
        <slot name="country-selector" />
      </div>

      <!--
        **
        * Secondary Menu
        **
      -->
      <div class="secondary-menu">
        <div
          v-for="(link, index) in mainMenu.content.secondaryMenus"
          :key="'menu' + index"
        >
          <div class="label">
            <span>{{ link.label }}</span>
          </div>
          <div
            v-for="(child, indexKey) in link.menuItems"
            :key="'child-' + indexKey"
            class="link"
          >
            <img
              v-if="child.icon.filename"
              :src="child.icon.filename"
              :alt="child.label"
              loading="lazy"
            >
            <mp-link
              :to="$u(child.link)"
              :target="child.targetBlank ? '_blank' : '_self'"
              :rel="child.targetBlank ? 'noreferrer nofollow' : false"
            >
              {{ child.label }}
            </mp-link>
          </div>
        </div>
      </div>
    </div>

    <!--
      **
      * Level 2 Menu (Submenu)
      **
    -->
    <div class="sidebar-body level-2">
      <div
        v-for="(submenu, index) in shopByMenu"
        :key="index"
        class="level2-section"
      >
        <div class="level2-headline">
          <span>
            {{ submenu.label }}
          </span>
        </div>
        <div class="level2-navigation">
          <div
            class="link"
            @click="trackMenuClick({
              label: mainMenu.content.linkToAllProductsLabel,
              _uid: 'all-products'

            })"
          >
            <mp-link
              v-if="mainMenu.content.linkToAllProductsLabel"
              :to="$u(mainMenu.content.linkToAllProducts)"
            >
              <span>
                {{ mainMenu.content.linkToAllProductsLabel }}
              </span>
            </mp-link>
          </div>
          <div
            v-for="(link, indexKey) in submenu.children"
            @click="trackMenuClick(link)"
            :key="indexKey"
            class="link"
          >
            <mp-link :to="$u(link.link)">
              <span>
                <img
                  v-if="link.image.filename"
                  :src="link.image.filename"
                  :alt="link.label"
                  loading="lazy"
                >
                <span>{{ link.label }}</span>
              </span>
              <Tag
                :text="link.tagLabel"
                :text-color="link.tagForegroundColor"
                :text-color-hex="link.tagForegroundColorHex"
                :background-color="link.tagBackgroundColor"
                :background-color-hex="link.tagBackgroundColorHex"
              />
            </mp-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChevronRightIcon from '~/assets/icons/chevron-right-large.svg'
import ChevronLeftIcon from '~/assets/icons/chevron-left-large.svg'
import CloseIcon from '~/assets/icons/cross.svg'
import Tag from '~/components/tag/Tag'
import CollectionImage from '~/components/header/collection-image/CollectionImage'
import GtmMixin from '~/mixins/stronger-gtm'

export default {
  name: 'SidebarMenu',
  components: {
    ChevronRightIcon,
    ChevronLeftIcon,
    CloseIcon,
    CollectionImage,
    Tag,
  },
  mixins: [GtmMixin],
  props: {
    mainMenu: {
      type: Object,
      required: false,
      default: null
    },
    collections: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    secondaryMenu: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    shopByProduct: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    shopByStyle: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    shopByActivity: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      levelVisible: 1,
      menuTitle: this.$t('Menu_Title')
    }
  },
  computed: {
    ...mapState({
      displayAccountIcon: state => state.ui.displayAccountIcon
    }),
    /**
     * Depending on the visibility setting (Mobile, Desktop, All, None),
     * only loop through visible items
     */
    visibleMainMenuItems () {
      return this.mainMenu.content.topLevel.filter(it => it.visibility === 'all' || it.visibility === 'mobile')
    },
    visibleCollections () {
      return this.mainMenu.content.collections.filter(it => it.visibility === 'all' || it.visibility === 'mobile')
    },
    /**
     * Join product items and style items together
     * in order to loop through them once
     */
    shopByMenu () {
      const output = []
      output.push(
        {
          label: this.mainMenu.content.shopByProductLabel,
          children: this.mainMenu.content.shopByProduct
        },
        {
          label: this.mainMenu.content.shopByStyleLabel,
          children: this.mainMenu.content.shopByStyle
        }
      )
      return output
    }
  },
  methods: {
    hideMobileSidebar () {
      this.$store.dispatch('ui/hideMobileSidebar')
    },
    trackMenuClick (menuItem) {
      this.gtm_trackMenuClick({ label: menuItem.label, parent: this.menuTitle, id: menuItem._uid, view: 'mobile' })
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-menu {
  width: calc(100% - 3.2rem);
  height: 100vh;
  max-width: 40rem;
  background-color: $night-100;
  color: $white;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    background: linear-gradient(180deg, rgba(39, 40, 44, 0) 0%, $night-100 100%);
    z-index: 2;
    pointer-events: none;
  }
  .sidebar-header {
    text-align: center;
    position: relative;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    height: 5.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    .title {
      @include h--small;
      margin: 0;
    }
    .back,
    .close {
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        display: block;
        width: 1.2rem;
        fill: $white;

        path {
          fill: $white;
        }
      }
    }
    .back svg {
      width: .9rem;
    }
  }
  .sidebar-body {
    position: absolute;
    width: 100%;
    height: calc(100vh - 5.6rem);
    overflow-y: auto;
    @include hide-scrollbars;
    z-index: 1;
    top: 5.6rem;
    left: 0;
    background: $night-100;
    transition: transform 0.6s cubic-bezier(0.5, 0, 0, 1);

    /**
     * Main Menu (Level 1)
     */
    .main-menu {
      margin: 2.8rem 0;
      .item {
        position: relative;
        display: block;
        .link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 2.4rem;
          line-height: 110%;
          font-weight: bold;
          padding: 1.2rem 3.2rem;
          color: var(--link-color);
          > span {
            display: flex;
            align-items: center;
            .tag {
              margin-left: 1.6rem;
              position: unset;
            }
          }
          svg {
            height: 1.6rem;
            fill: $white;
            path {
              fill: $white;
            }
          }
        }
      }
    }

    /**
     * Level 2 Menu
     */
    .level2-section {
      //margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 10rem;
      }
      .level2-headline {
        @include h--small;
        margin: 0;
        padding: 1.6rem 1.6rem 1.6rem 1.6rem;
        position: sticky;
        top: 0;
        background: $night-175;
        height: 5.6rem;
        display: flex;
        align-items: center;
      }
      .level2-navigation {
        .link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          @include p--small;
          > a,
          > span {
            display: flex;
            padding: 0.8rem 1.6rem;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            min-height: 5.6rem;
            > span {
              display: flex;
              min-width: 50%;
              align-items: center;
              img {
                object-fit: cover;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                margin-right: 1.6rem;
              }
              span {
                //width: 35%;
              }
            }
            .tag {
              margin-left: 1.6rem;
              position: unset;
            }
          }
          &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
    }

    /**
     * Collection Images
     */
    .collections {
      .collection-section {
        margin: 2.8rem 0;
        padding: .8rem 2.4rem;
        .collection-headline {
          padding: 0 .8rem;
          @include p--large;
          font-weight: 600;
          margin-bottom: 1.6rem;
        }
        .collection {
         display: flex;
         flex-direction: column;
         gap: 12px;

          &::v-deep {
            .collection-image {
              margin-bottom: 1.6rem;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
        .button {
          display: none;
        }
      }
    }

    /**
     * Country Selector
     */
    .country-wrapper {
      margin: 4rem 0;
      padding: 0 3.2rem;
      .label {
        @include h--small;
        margin: 0;
        padding: 0.8rem 0;
      }
      .country-selector {
        @include p--medium;
        ::v-deep .selected-country .chevron {
          path {
            fill: white;
          }
        }
      }
    }

    /**
     * Secondary Menu
     */
    .secondary-menu {
      padding: 0 3.2rem 4rem 3.2rem;
      margin: 4rem 0;
      > * {
        margin-bottom: 4rem;
        .label {
          @include h--small;
          margin: 0;
          padding: 0.8rem 0;
        }
        .link {
          @include p--small;
          padding: 0.3rem 0;
          display: flex;
          img {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }

  /**
   * Transition between level-1 and level-2
   */
  .sidebar-body.level-2 {
    //background: red;
  }
  &.level-1-visible {
    .sidebar-body.level-1 {
      transform: translateX(0);
    }
    .sidebar-body.level-2 {
      transform: translateX(100%);
    }
  }
  &.level-2-visible {
    .sidebar-body.level-1 {
      transform: translateX(-100%);
    }
    .sidebar-body.level-2 {
      transform: translateX(0);
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .sidebar-menu {
    //display: none;
  }
}
</style>
