<template>
  <div class="submenu">
    <div class="shop-by-product">
      <div class="submenu-section">
        <div class="submenu-headline">
          <span>{{ mainMenu.content.shopByProductLabel }}</span>
        </div>
        <div class="submenu-navigation">
          <div
            v-for="(link, typeIndex) in mainMenu.content.shopByProduct"
            :key="typeIndex"
            class="link"
            @click="menuClick(link)"
          >
            <mp-link :to="$u(link.link)">
              {{ link.label }}
            </mp-link>
            <Tag
              v-if="link.tagLabel"
              :text="link.tagLabel"
              :text-color="link.tagForegroundColor"
              :background-color="link.tagBackgroundColor"
            />
          </div>
        </div>
        <div @click="menuClick({ label: mainMenu.content.linkToAllCollectionsLabel, _uid: 'all-products'})">
          <mp-link
            v-if="mainMenu.content.linkToAllProductsLabel"
            :to="$u(mainMenu.content.linkToAllProducts)"
            class="button simple white view-more"
          >
            <span class="label">
              {{ mainMenu.content.linkToAllProductsLabel }}
            </span>
          </mp-link>
        </div>
      </div>
    </div>
    <div class="shop-by-style">
      <div class="submenu-section">
        <div class="submenu-headline">
          <span>
            {{ mainMenu.content.shopByStyleLabel }}
          </span>
        </div>
        <div class="submenu-navigation">
          <div
            v-for="(link, styleIndex) in mainMenu.content.shopByStyle"
            :key="styleIndex"
            class="link"
            @click="menuClick(link)"
          >
            <mp-link :to="$u(link.link)">
              <img
                v-if="link.image.filename"
                :src="$imgproxy.transform(link.image.filename, 'preset:sharp/rs:fit:80/g:nowe/q:70')"
                :alt="link.label"
                loading="lazy"
              >
              <span>
                {{ link.label }}
              </span>
            </mp-link>
          </div>
        </div>
      </div>
    </div>
    <div class="collections">
      <div class="collection-section">
        <div class="submenu-headline">
          {{ mainMenu.content.collectionsLabel }}
        </div>
        <div class="collection dc--2" @click="hideDesktopSubmenu">
          <CollectionImage
            v-for="(collection, indexKey) in visibleCollections"
            :key="indexKey"
            :image="collection.image"
            :label="collection.label"
            :link="collection.link"
            view="desktop"
          />
        </div>
        <div @click="menuClick({ label: mainMenu.content.linkToAllCollectionsLabel, _uid: 'all-collections'})">
          <mp-link
            v-if="mainMenu.content.linkToAllCollectionsLabel"
            :to="$u(mainMenu.content.linkToAllCollections)"
            class="button simple white view-more"
          >
            <span
              class="label"
            >
              {{ mainMenu.content.linkToAllCollectionsLabel }}
            </span>
          </mp-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionImage from '~/components/header/collection-image/CollectionImage.vue'
import Tag from '~/components/tag/Tag.vue'
import GtmMixin from '~/mixins/stronger-gtm'

export default {
  name: 'DesktopSubmenu',
  components: {
    CollectionImage,
    Tag
  },
  mixins: [GtmMixin],
  props: {
    mainMenu: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    visibleCollections () {
      return this.mainMenu.content.collections.filter(it => it.visibility === 'all' || it.visibility === 'desktop')
    }
  },
  methods: {
    hideDesktopSubmenu () {
      this.$store.dispatch('ui/hideDesktopSubmenu')
    },
    menuClick (link) {
      this.$store.dispatch('ui/hideDesktopSubmenu')
      this.gtm_trackMenuClick({
        label: link.label,
        id: link._uid,
        parent: 'shop',
        view: 'desktop'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.submenu {
  display: none;
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .submenu {
    display: flex;
    //position: absolute;
    width: 90vw;
    max-width: 142.4rem;
    background-color: $night-100;
    padding: 4vw;
    > * {
      display: inline-block;
      color: $white;
      .submenu-headline {
        @include p--large;
        font-weight: 600;
        margin-bottom: 2.4rem;
      }
      .submenu-navigation {
        .link {
          @include p--medium;
        }
      }
    }
    .shop-by-product,
    .shop-by-style {
      min-width: 18rem;
      margin-right: 2.4rem;
      .button-container {
        margin-top: 4.8rem;
        padding: 3.2rem 0;
      }
    }
    .shop-by-product {
      .submenu-navigation {
        .link {
          margin-bottom: 0.8rem;
          padding: 0.25rem 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            border-bottom: 1px solid transparent;
            @media (hover: hover) {
              &:hover {
                border-color: white;
              }
            }
          }
          .tag {
            margin-left: .8rem;
            font-size: .8rem;
            padding: 0.3rem 0.4rem;
            border-radius: .2rem;
            margin-top: -.2rem;
          }
        }
      }
    }
    .shop-by-style {
      position: relative;
      background-color: transparent;
      .submenu-section {
        margin-bottom: 2.4rem;
        &:last-child {
          margin-bottom: 0;
        }
        .submenu-navigation {
          .link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include p--medium;
            > a,
            > span {
              display: flex;
              padding: 0.8rem 0;
              width: 100%;
              align-items: center;
              img {
                object-fit: cover;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                margin-right: 1.6rem;
              }
              // span {
              //   border-bottom: 1px solid transparent;
              // }
              .banner-container {
                position: absolute;
                right: 1.6rem;
                &::v-deep .banner-small {
                  position: unset;
                }
              }
            }
          }
        }
      }
    }
    .collections {
      width: 100%;
      .collection-section {
        padding: 0;
        margin-bottom: 3.2rem;
        .submenu-headline {
          @include p--large;
          font-weight: 600;
          margin-bottom: 2.4rem;
        }
        .collection {
          grid-gap: 2.0rem;
          padding: 0;
          margin-bottom: 1rem;
          .collection-image {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .button.view-more {
      margin-top: 5.6rem;
      span {
        border-bottom: 1px solid white;
      }
      @media (hover: hover) {
        &:hover {
          span {
            border-color: transparent;
          }
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .submenu {
    padding: 5.6rem;
    .shop-by-product,
    .shop-by-style {
      min-width: 18rem;
    }
  }
}
</style>
