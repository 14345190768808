/**
 * Mixin this and use it to figure out if the current block
 * is visible in the browser window or not
 */
export default {
  data: () => ({
    seenInViewport: false // Can be used to prevent double GTM tracking for instance
  }),
  mounted () {
    window.addEventListener('scroll', this.inViewportHandler)
    this.inViewportHandler()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.inViewportHandler)
  },
  methods: {
    testInViewport () {
      let inViewport = false
      if (this?.$el?.getBoundingClientRect) {
        const bounding = this.$el.getBoundingClientRect()
        inViewport = (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        )
        this.seenInViewport = this.seenInViewport || inViewport
      }
      return inViewport
    },
    inViewportHandler () {
      // Overwrite this to have it trigger when the component is in the viewport
    }
  }
}
